<template>
  <section class="section-profile section-virtual-card">
    <h2 class="section-profile__title">
      Настройки виртуальной карты
    </h2>

    <vPaymentInfo
      v-if="ERROR"
      :type="alertType"
      :msg="ERROR"
    />

    <vVirtualCard
      v-if="cardData"
      :status="VIRTUAL_CARD_INFO.status"
      :data="cardData"
      :balance="VIRTUAL_CARD_BALANCE"
    />

    <!-- STEP 0 -->
    <vStepZero
      v-if="step === 0"
      :token="GET_AUTHENTIFICATED"
      @togglePreloader="toggleLightPreloader"
      @nextStep="nextStep"
    />

    <!-- STEP 1 -->
    <vStepOne
      v-if="step === 1"
      :token="GET_AUTHENTIFICATED"
      :reg="reg"
      @togglePreloader="toggleLightPreloader"
      @nextStep="nextStep"
    />

    <!-- STEP 2 -->
    <vStepTwo
      v-if="step === 2"
      :token="GET_AUTHENTIFICATED"
      :reg="reg"
      @togglePreloader="toggleLightPreloader"
      @nextStep="nextStep"
    />

    <!-- STEP 3 -->
    <vStepThree
      v-if="step === 3"
      :token="GET_AUTHENTIFICATED"
      :reg="reg"
      @togglePreloader="toggleLightPreloader"
      @nextStep="nextStep"
      @prevStep="prevStep"
    />

    <!-- STEP 4 -->
    <vStepFour
      v-if="step === 4"
      :token="GET_AUTHENTIFICATED"
      @togglePreloader="toggleLightPreloader"
      @nextStep="nextStep"
    />

    <!-- STEP 5 -->
    <vStepFive
      v-if="step === 5"
      :token="GET_AUTHENTIFICATED"
      @togglePreloader="toggleLightPreloader"
    />

    <vLightPreloader v-if="preloader" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vLightPreloader from '@/components/v-light-preloader'
import vVirtualCard from '@/components/virtualCard/v-virtual-card'
import vStepZero from '@/components/virtualCard/v-step-zero'
import vStepOne from '@/components/virtualCard/v-step-one'
import vStepTwo from '@/components/virtualCard/v-step-two'
import vStepThree from '@/components/virtualCard/v-step-three'
import vStepFour from '@/components/virtualCard/v-step-four'
import vStepFive from '@/components/virtualCard/v-step-five'
import vPaymentInfo from '@/components/v-payment-info'
export default {
  name: 'VSectionVirtualCard',
  components: {
    vLightPreloader,
    vVirtualCard,
    vStepZero,
    vStepOne,
    vStepTwo,
    vStepThree,
    vStepFour,
    vStepFive,
    vPaymentInfo,
  },
  data() {
    return {
      step: 0,
      modalOutputVisible: false,
      alert: false,
      typeAlert: 'success',
      preloader: false,
      reg: false,
      cardData: null,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
    ...mapGetters('virtualCard', [
      'VIRTUAL_CARD_INFO',
      'VIRTUAL_CARD_BALANCE',
      'ERROR',
    ]),
  },
  async mounted() {
    await this.FETCH_VIRTUAL_CARD_INFO(this.GET_AUTHENTIFICATED)
    await this.GET_BALANCE_VIRTUAL_CARD(this.GET_AUTHENTIFICATED)

    // Если это новый официант то шаг 1 доступен
    if (this.VIRTUAL_CARD_INFO.status !== 'notReadyet') {
      this.reg = true
      this.step = 1
    }

    // Если есть номер телефона то шаг 2 доступен
    if (
      this.VIRTUAL_CARD_INFO.data &&
      this.VIRTUAL_CARD_INFO.data.virtual_card_phone
    ) {
      this.phone = this.VIRTUAL_CARD_INFO.data.virtual_card_phone
      this.step = 2
    }

    // Если status_doc 1 то шаг 3 доступен
    if (
      this.VIRTUAL_CARD_INFO.data &&
      this.VIRTUAL_CARD_INFO.data.virtual_card_status_doc === 1
    ) {
      this.step = 3
    }

    // Если status_doc 2 то шаг 4 доступен
    if (
      this.VIRTUAL_CARD_INFO.data &&
      this.VIRTUAL_CARD_INFO.data.virtual_card_status_doc === 2
    ) {
      this.step = 4
    }

    // Если есть virtual_card_token то шаг 5 доступен
    if (
      this.VIRTUAL_CARD_INFO.data &&
      this.VIRTUAL_CARD_INFO.data.virtual_card_token
    ) {
      this.step = 5
    }

    this.cardData = {
      name: this.USER_DATA?.name,
      restaurant: this.USER_DATA?.shop_linked?.name,
    }
    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('virtualCard', [
      'FETCH_VIRTUAL_CARD_INFO',
      'GET_BALANCE_VIRTUAL_CARD',
    ]),
    toggleLightPreloader() {
      this.preloader = !this.preloader
    },
    prevStep() {
      this.toggleLightPreloader()
      this.step--
      setTimeout(() => {
        this.toggleLightPreloader()
      }, 500)
    },
    async nextStep() {
      if (this.step === 0) {
        await this.FETCH_VIRTUAL_CARD_INFO(this.GET_AUTHENTIFICATED)
        // Если это новый официант то шаг 1 доступен
        if (this.VIRTUAL_CARD_INFO.status !== 'notReadyet') {
          this.reg = true
        }
      }
      this.step++
    },
  },
}
</script>

<style lang="scss">
.section-virtual-card {
  button {
    cursor: pointer;
    &:disabled {
      color: #343d5d !important;
      background: #edeef1 !important;
      cursor: not-allowed;
    }
  }
}
</style>

<template>
  <div :class="`payment-information ${type}`">
    <svg
      v-if="type === 'danger'"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 32C12.2565 32 8.18697 30.3144 5.18624 27.3138C2.18565 24.3132 0.5 20.2433 0.5 16C0.5 11.7567 2.18565 7.68697 5.18624 4.68624C8.18683 1.68565 12.2567 0 16.5 0C20.7433 0 24.813 1.68565 27.8138 4.68624C30.8144 7.68683 32.5 11.7567 32.5 16C32.4954 20.2421 30.8082 24.3089 27.8084 27.3083C24.8089 30.308 20.742 31.9953 16.5001 31.9999L16.5 32ZM16.5 3.19959C13.1051 3.19959 9.8493 4.54827 7.44913 6.94885C5.04854 9.34916 3.69986 12.605 3.69986 15.9997C3.69986 19.3945 5.04854 22.6504 7.44913 25.0506C9.84944 27.4512 13.1053 28.7999 16.5 28.7999C19.8947 28.7999 23.1507 27.4512 25.5509 25.0506C27.9515 22.6503 29.3001 19.3945 29.3001 15.9997C29.2961 12.6062 27.9464 9.35245 25.5466 6.95297C23.1471 4.55321 19.8934 3.20343 16.4999 3.19945L16.5 3.19959ZM18.0999 17.5998V9.60014C18.0999 9.02852 17.7951 8.5003 17.3001 8.21449C16.8051 7.92868 16.1949 7.92868 15.6999 8.21449C15.2049 8.5003 14.9001 9.02852 14.9001 9.60014V17.6005V17.6002C14.9001 18.1718 15.2049 18.7 15.6999 18.9859C16.1949 19.2717 16.8051 19.2717 17.3001 18.9859C17.7951 18.7 18.0999 18.1718 18.0999 17.6002V17.5998ZM16.5 24.0075V24.0078C16.0736 24.0078 15.6645 23.8382 15.3632 23.5369C15.0616 23.2353 14.8923 22.8263 14.8923 22.3998C14.8923 21.9734 15.0616 21.5646 15.3632 21.263C15.6645 20.9614 16.0735 20.7921 16.5 20.7921C16.9265 20.7921 17.3355 20.9614 17.6368 21.263C17.9384 21.5647 18.1077 21.9734 18.1077 22.3998C18.1064 22.826 17.9365 23.234 17.6352 23.5353C17.3341 23.8364 16.9259 24.0062 16.5 24.0078L16.5 24.0075Z"
        fill="#FB2E13"
      />
    </svg>

    <svg
      v-if="type === 'success'"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 30.5C8.49109 30.5 2 24.0089 2 16C2 7.99109 8.49109 1.5 16.5 1.5C24.5089 1.5 31 7.99109 31 16C31 24.0089 24.5089 30.5 16.5 30.5Z"
        stroke="#25C26E"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.1668 12L14.8335 21.3333L9.8335 15.7333"
        stroke="#25C26E"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <p>{{ msg }}</p>
    <!-- <p v-if="type === 'danger'">Платёж не прошёл</p> -->
    <!-- <p v-if="type === 'success'">Операция прошла успешно</p> -->
  </div>
</template>

<script>
export default {
  name: 'VPaymentInfo',
  props: {
    type: {
      type: String,
      required: false,
      default: 'danger',
    },
    msg: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.payment-information {
  margin-bottom: 20px;
  width: 100%;
  height: 56px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
  }
}
.danger {
  border: 1px solid #ec4e4e;
}
.success {
  border: 1px solid #a6e5c3;
}
</style>

<template>
  <div class="vStep">
    <div class="vStep__form">
      <vButton @handleClick="addCard">
        Выпустить виртуальную карту
      </vButton>
    </div>
    <div class="vStep__info">
      <div class="vStep__description">
        <h2>Шаг 1</h2>
        <p>Выпустите виртуальную карту, чтобы накапливать на ней деньги</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import vButton from '@/components/v-button'
export default {
  name: 'VStepOne',
  components: {
    vButton,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: ['togglePreloader', 'nextStep'],
  methods: {
    ...mapActions('virtualCard', ['REGISTER_VIRTUAL_CARD_INFO']),
    async addCard() {
      this.$emit('togglePreloader')
      await this.REGISTER_VIRTUAL_CARD_INFO(this.token)
      this.$emit('nextStep')
      this.$emit('togglePreloader')
    },
  },
}
</script>

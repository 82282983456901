<template>
  <div class="vVirtualCard">
    <div class="card">
      <div
        class="card-fone"
        :class="status"
      >
        <div class="card-fone__number">
          {{ balance }} &#8381;
        </div>
        <div class="card-fone__place-name">
          <div class="card-fone__place">
            {{ data.restaurant }}
          </div>
          <div class="card-fone__name">
            {{ data.name }}
          </div>
        </div>
      </div>
      <div
        v-if="status !== 'active'"
        class="not-activated"
      >
        <svg
          v-if="status === 'inactive'"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
            stroke="#EC4E4E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.749 8C11.6772 8 11.6125 8.03028 11.5671 8.07874C11.5251 8.12349 11.4995 8.18375 11.5 8.25C11.5 8.29461 11.5117 8.3365 11.5322 8.37276C11.5751 8.4487 11.6566 8.5 11.75 8.5C11.8434 8.5 11.9249 8.4487 11.9678 8.37276C11.9883 8.3365 12 8.29461 12 8.25C12 8.17383 11.9659 8.10559 11.9119 8.05972C11.8681 8.02249 11.8113 8 11.749 8Z"
            fill="#C4C4C4"
          />
          <path
            d="M12 17V12H11M11.9119 8.05972C11.9659 8.10559 12 8.17383 12 8.25C12 8.29461 11.9883 8.3365 11.9678 8.37276M11.9119 8.05972C11.8681 8.02249 11.8113 8 11.749 8C11.6772 8 11.6125 8.03028 11.5671 8.07874M11.9119 8.05972L11.749 8.45312L11.5671 8.07874M11.5671 8.07874C11.5251 8.12349 11.4995 8.18375 11.5 8.25C11.5 8.29461 11.5117 8.3365 11.5322 8.37276M11.5322 8.37276C11.5751 8.4487 11.6566 8.5 11.75 8.5C11.8434 8.5 11.9249 8.4487 11.9678 8.37276M11.5322 8.37276L11.75 8.25L11.9678 8.37276"
            stroke="#EC4E4E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <p v-if="status === 'inactive'">
          Карта не активирована
        </p>
        <p v-if="status === 'notReadyet'">
          Карта не выпущена
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VVirtualCard',
  props: {
    status: {
      type: String,
      required: true,
      default: 'status',
    },
    balance: {
      type: Number,
      required: true,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: 'Test',
          restaurant: 'Много мяса',
        }
      },
    },
  },
}
</script>
<style lang="scss">
.vVirtualCard {
  .card {
    max-width: 326px;
    width: 100%;
    height: 205px;
    position: relative;
    &-fone {
      width: 100%;
      height: 100%;
      padding: 20px;
      background-color: #010d35;
      color: #fff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.notReadyet {
        background-color: #dcdee3;
      }
      &__number {
        font-size: 24px;
        font-weight: 600;
      }
      &__place-name {
        &__place {
          font-size: 13px;
        }
        &__name {
          font-size: 17px;
        }
      }
    }
    .not-activated {
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(4px);
      border-radius: 20px;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      p {
        font-size: 17px;
        font-weight: 600;
        color: #fff;
        margin-left: 5px;
      }
    }
  }
}
</style>
